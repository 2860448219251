import { Link } from "gatsby"
import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import SEO from "../components/App/SEO"

const ErrorPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <Navbar />
      <div className="error-area">
        <div className="error-content">
          <h3>404</h3>
          <h4>Oops! Page not found</h4>
          <p>
            Sorry, the page you're looking for doesn't exist. If you think
            something is broken, report a problem.
          </p>
          <div className="btn-box">
            <Link to="/" className="default-btn primary-btn">
              Return Home
            </Link>
            <Link to="/contact" className="default-btn">
              Report Problem
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage
